import { render, staticRenderFns } from "./OnBoardingVendorPageNew.vue?vue&type=template&id=05d2f02e&"
import script from "./OnBoardingVendorPageNew.vue?vue&type=script&lang=js&"
export * from "./OnBoardingVendorPageNew.vue?vue&type=script&lang=js&"
import style0 from "./OnBoardingVendorPageNew.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VAutocomplete,VCheckbox,VChip,VChipGroup,VDialog,VListItemContent,VListItemTitle,VRadio,VRadioGroup})
