<template>
  <div>
    <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
      <h3 class="tw-text-xl tw-font-semibold tw-leading-8">
        {{$t('vendor.setting.on-the-spot')}}
      </h3>
      <div class="tw-text-gray-n4 tw-text-sm">
        <h4 class="tw-text-lg tw-text-black tw-mb-2 tw-leading-relaxed">What is <strong class="tw-font-semibold">Auto-Booking</strong>?</h4>
        <p class="tw-leading-relaxed tw-text-sm ">Auto-Booking package booking does not require approval from the wedding vendor before they can be booked.
          Instead, couples can just choose their wedding date, package, book, and discuss wedding day details with their
          chosen wedding professional after the booking.</p>
        <h5 class="tw-text-black tw-text tw-text-black tw-mb-2 tw-font-semibold">For wedding professionals</h5>
        <p class="tw-mb-3">If you have <strong class="tw-font-semibold">Auto-Booking</strong> on, it will apply to all available dates on your calendar.
          Couples who meet your requirements will be able to automatically book your services and packages.</p>
        The benefits of Auto-Booking include:
        <ul class="tw-list-disc tw-pl-4 tw-space-y-1 tw-mt-1">
          <li>Couples book your open dates without you having to respond to each request.</li>
          <li>Couples can use filters to search for packages that can be booked on the spot. These packages are more
            popular with couples since they are able to more easily plan their wedding.</li>
        </ul>
      </div>

      <h3 class="tw-text-xl tw-font-semibold tw-leading-8">
        Booking request
      </h3>
      <div class="tw-text-gray-n4">
        <h4 class="tw-text-lg tw-text-black tw-mb-2 tw-leading-relaxed">What is <strong>Booking request</strong>?</h4>
        <p>Booking request is for vendors who wants to approve (in 48h) every booking before final state.</p>

      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'BookingOptions'
}
</script>
