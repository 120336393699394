<template>
  <ml-container>
      <div v-if="errors.length && 1==2" class="tw-pt-24 tw-text-center">
        <div>
          <b>{{ $t('general.error_label') }}</b>
          <ul>
            <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>

    <div class="tw-w-2/5 tw-mx-auto tw-mt-32" v-if="showSuccess">
      <div class="tw-flex tw-justify-center">
        <success/>
      </div>
      <div class="tw-mt-14 tw-text-center">
        <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">
          {{ $t('vendor.onboarding.successfully_message') }}
        </h1>
        <p class="tw-text-sm tw-text-gray-n3 tw-leading-relaxed tw-mb-0">
          {{ $t('vendor.onboarding.successfully_suggestion_message') }}
        </p>
      </div>
      <router-link :to="{ name: 'vendors.showcaseWork' }"
                   class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('vendor.onboarding.successfully_showcase_button') }}
      </router-link>
      <router-link :to="{ name: 'feed' }" class="tw-mt-4 tw-text-sm tw-text-black tw-block tw-text-center">
        {{ $t('vendor.onboarding.successfully_other_button') }}
      </router-link>
    </div>
    <div class="tw-w-2/5 tw-mx-auto tw-mt-32" v-if="!showSuccess">
    <form ref="forma" novalidate="false" @submit.prevent="onSubmit" @change="validateStep(step)" method="POST">
      <div v-if="step === 1">
        <div class="tw-flex tw-justify-center">
          <cheers/>
        </div>
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">
            Dear Vendor
          </h1>
          <p class="tw-text-sm tw-text-gray-n4 tw-mb-3">
            Welcome to <span class="tw-font-serif tw-text-red">Mango & Lola</span>
          </p>
          <p class="tw-text-sm tw-text-gray-n3 tw-leading-relaxed tw-mb-0">
            We want to know more about you! To have the best experience, please fill out these fields below with your information.
          </p>
        </div>
        <div class="tw-mt-8">
          <ml-label required for="company_name">{{ $t('vendor.data.company_name') }}</ml-label>
          <div class="tw-mt-2">

            <ml-input-text v-model="formData.step1.name" id="company_name"
                           name="company_name" required/>
              <ml-error-msg :msg="errors['company_name']" />
          </div>
        </div>
        <button type="button" @click="nextStep"
                class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
          {{ $t('general.next') }}
        </button>
      </div>
      <div v-else-if="step === 2">
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('vendor.data.company_info') }}
          </h1>
        </div>
        <div class="tw-mt-8">
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <div class="tw-col-span-1">
              <ml-label required for="owner_first_name">{{ $t('vendor.data.owner_first_name') }}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="formData.step2.owner_first_name" id="owner_first_name"
                               name="owner_first_name" required/>
                               <ml-error-msg :msg="errors['owner_first_name']" />
              </div>
            </div>
            <div class="tw-col-span-1">
              <ml-label required for="owner_last_name">{{ $t('vendor.data.owner_last_name') }}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="formData.step2.owner_last_name" id="owner_last_name"
                               name="owner_last_name" required/>
                               <ml-error-msg :msg="errors['owner_last_name']" />
              </div>
            </div>
          </div>
        </div>
        <div class="tw-mt-6">
          <ml-label required for="address_line_1">{{ $t('vendor.data.address') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step2.address1" id="address_line_1"
            :placeholder="address1_placeholder"
                           name="address_line_1" required />
            <ml-error-msg :msg="errors['address1']" />
          </div>
        </div>       
        <div class="tw-mt-6">
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step2.address2" id="address_line_2"
                           :placeholder="address2_placeholder"
                           name="address_line_2" required />
            <ml-error-msg :msg="errors['address2']" />
          </div>
        </div>
        <div class="tw-mt-6">
          <ml-label required for="zip">{{ $t('vendor.data.zip') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step2.zip" id="zip" name="zip"  required/>
            <ml-error-msg :msg="errors['zip']" />
          </div>
        </div>         
        <div class="tw-mt-6">
          <ml-label required for="city">{{ $t('vendor.data.city') }}</ml-label>
          <div class="tw-mt-2">

            <v-autocomplete
                class="text-caption remove-bg-fix location-field-onboarding-fix"
                rounded
                large
                v-model="selectedCities"
                return-object
                :items="cities_by_google"
                :search-input.sync="searchCities"
                :hide-no-data="!searchCities"
                :loading="loadingCities"
                :persistent-hint="false"
                append-icon=""
                :clearable="true"
                autocomplete="new-password"
                :hide-selected="false"
                :deletable-chips="true"
                :no-filter="true"
                :cache-items="false"
                id="location-field"
                :placeholder="city_placeholder"
                @click="clearAreas"
                :rules="[rules.required]"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-text="data.item.description"></v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:selection="data">
                <v-chip small>
                  {{data.item.city_name}}
                </v-chip>
              </template>
            </v-autocomplete>
            <ml-error-msg :msg="errors['city']" />
          </div>
        </div>

        <!--          <div class="tw-mt-6">
                    <label for="company_name" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      Edit link
                    </label>
                    <slug-widget :url="url" subdirectory="showcasework"
                                 :title="formData.step1.name"
                                 @slug-changed="updateSlug"
                                 @slug-reset="resetSlug"
                                 @slug-save="saveSlug"
                    ></slug-widget>
                  </div>-->

        <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <button type="button" @click="previousStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.previous') }}
          </button>
          <button type="button" @click="nextStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.next') }}
          </button>
        </div>
      </div>
      <div v-else-if="step === 3">
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('vendor.data.contact_tax_info') }}
          </h1>
        </div>
        <div class="tw-mt-8">
          <ml-label required for="phone">{{ $t('vendor.data.phone') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step3.phone" id="phone" name="phone" required/>
            <ml-error-msg :msg="errors['phone']" />
          </div>
        </div>
        <div class="tw-mt-6">
          <ml-label for="tin">{{ $t('vendor.data.tin') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step3.tin" id="tin" name="tin"/>
          </div>
        </div>
        <div class="tw-mt-6">
          <ml-label for="instagram">{{ $t('vendor.data.instagram') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step3.instagram_link" id="instagram" name="instagram"/>
          </div>
        </div>
        <div class="tw-mt-6">
          <ml-label for="facebook">{{ $t('vendor.data.facebook') }}</ml-label>
          <div class="tw-mt-2">
            <ml-input-text v-model="formData.step3.facebook_link" id="facebook" name="facebook"/>
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <button type="button" @click="previousStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.previous') }}
          </button>
          <button type="button" @click="nextStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.next') }}
          </button>
        </div>
      </div>
      <div v-else-if="step === 4">
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('vendor.data.select_vendor_categories') }}

          </h1>
        </div>
        <div class="tw-mt-8">
          <ml-label for="facebook">{{ $t('vendor.data.select_vendor_categories_label') }}</ml-label>

          <div class="tw-mt-2">
            <v-chip-group
                v-model="formData.step31.categories"
                column
                multiple
                active-class="deep-purple darken-4"
                mandatory
            >

              <v-chip v-for="category in categories"
                      :key="category.id"
                      filter
                      outlined
                      :value="category.id"
              >
                {{category.name}}
              </v-chip>

            </v-chip-group>
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <button type="button" @click="previousStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.previous') }}
          </button>
          <button type="button" @click="nextStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.next') }}
          </button>
        </div>
      </div>
      <div v-else-if="step === 5">
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('general.almost_finished') }}
          </h1>
        </div>
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('vendor.data.calendar_availability') }}
          </h1>
        </div>
        <div class="tw-mt-8">
          <h5 class="tw-font-semibold tw-leading-relaxed tw-mb-2">
            {{ $t('vendor.data.availability_option') }}
          </h5>
          <div class="tw-text-sm">
            <strong class="tw-font-semibold">{{ $t('vendor.setting.all_dates_open') }}</strong>
            <p class="tw-text-gray-n3">{{ $t('vendor.setting.all_dates_open_label') }}</p>
          </div>
          <div>
            <v-radio-group v-model="formData.step3.availability_check" :mandatory="true">
              <v-radio color="#2D2D2D" :ripple="false" class="tw-text-radio-sm"
              :label="$t('vendor.provide_one_service')" value="vendor"></v-radio>
              <v-radio color="#2D2D2D" :ripple="false" class="tw-text-radio-sm"
              :label="$t('vendor.provide_many_service')" value="package"></v-radio>
            </v-radio-group>
            <ml-error-msg :msg="errors['availability_check']" />
          </div>

          <v-dialog
              v-model="dialogStep3"
              max-width="600"
          >
            <BookingOption/>
          </v-dialog>
        </div>
        <div class="tw-mt-6">
          <div class="tw-flex tw-justify-between tw-items-center">
            <span class="tw-font-semibold">{{ $t('vendor.setting.booking_option') }}</span>
            <a href="#" class="tw-text-gray-n3" @click.prevent="dialogStep3 = !dialogStep3">
              <help-circle-icon/>
            </a>
          </div>
          <div>
            <v-radio-group
                v-model="formData.step3.auto_booking"
                mandatory
            >
              <v-radio
                  :label="$t('vendor.setting.booking_request')"
                  :value="false"
                  class="tw-text-radio-sm"
                  color="#2D2D2D"
                  :ripple="false"
              ></v-radio>
              <v-radio
                  :label="$t('vendor.setting.on-the-spot')"
                  :value="true"
                  class="tw-text-radio-sm"
                  color="#2D2D2D"
                  :ripple="false"
              ></v-radio>
            </v-radio-group>
            <ml-error-msg :msg="errors['auto_booking']" />
          </div>
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <button type="button" @click="previousStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.previous') }}
          </button>
          <button type="button" @click="nextStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.submit') }}
          </button>
        </div>
      </div>
      <div v-else-if="step === 6">
        <div class="tw-mt-14 tw-text-center">
          <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-0">
            {{ $t('general.almost_finished') }}
          </h1>
        </div>
        <div class="tw-mt-8" v-if="privacy">
          <div class="tw-text-lg tw-font-semibold tw-mb-2">
            <router-link :to="{name: 'privacy.policy'}" target="_blank"> {{privacy.name}}</router-link>
          </div>

          <v-checkbox v-model="formData.step5.privacy_validate"
                      :rules="[rules.required1]"
                      @change="validateStep(step)"
                      :value="privacy.id"
                      :ripple="false"
                      color="#2D2D2D"
                      class="tw-text-radio-sm"
                      :label="$t('general.i_agree')"></v-checkbox>
          <ml-error-msg :msg="errors['privacy_validate']" />
        </div>
        <div class="tw-mt-6" v-if="terms">
          <div  class="tw-text-lg tw-font-semibold tw-mb-2">
            <router-link :to="{name: 'terms.and.conditions'}" target="_blank"> {{terms.name}}</router-link>
          </div>

          <v-checkbox v-model="formData.step5.terms_validate"
                      :rules="[rules.required1]"
                      :value="terms.id"
                      @change="validateStep(step)"
                      :ripple="false"
                      color="#2D2D2D"
                      class="tw-text-radio-sm"
                      :label="$t('general.i_agree')"></v-checkbox>
                      <ml-error-msg :msg="errors['terms_validate']" />
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <button type="button" @click="previousStep"
                  class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('general.previous') }}
          </button>
        <button type="button" @click="nextStep"
                class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
          {{ $t('general.submit') }}
        </button>
        </div>

      </div>
    </form>
    </div>
  </ml-container>
</template>


<script>

import BookingOption from '../components/help/BookingOptions.vue'

import MlContainer from "@/components/layout/MlContainer";
import Cheers from "@/components/special-icons/Cheers";
//import SlugWidget from "@/components/SlugWidget";
import Slug from "slug";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
import { HelpCircleIcon } from "vue-feather-icons";
import Success from "@/components/special-icons/Success";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import i18n from '@/i18n'

export default {
  middleware: ['auth', 'vendor'],
  components: {
    MlErrorMsg,
    Success,
    MlInputText,
    MlLabel,
    Cheers,
    MlContainer,
    BookingOption,
    HelpCircleIcon
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }],
      title: i18n.t('onboardingprocess'),
    }
  },
  data() {
    return {
      address1_placeholder: i18n.t('vendor.data.address1_placeholder'),
      address2_placeholder: i18n.t('vendor.data.address2_placeholder'),
      city_placeholder: i18n.t('vendor.data.city'),
      showSuccess: false,
      loading: false,
      errors: [],
      valid: false,
      step: 1,
      formData: {
        step1: {
          name: null,
          //country: [],
          //slug: null,
        },
        step2: {
          owner_first_name: null,
          owner_last_name: null,
          address1: null,
          address2: null,
          city: [],
          state: [],
          zip: null,
          //categories: []
        },
        step3: {
          phone: null,
          tin: null,
          instagram_link: null,
          facebook_link: null,
        },
        step31: {
          categories: [],
        },
        step4: {
          set_my_available_dates: 0,
          availability_check: 'package',
          auto_booking: false,
          //countries: [],
          //cities: [],
        },
        step5: {
          privacy_validate: null,
          terms_validate: null
        },

      },
      categories: [],
      countries: [],
      cities: [],
      searchCities: null,
      loadingCities: false,
      loadingCity: false,
      errored: false,
      privacy: '',
      terms: '',
      rules: {
        required1: value => !!value || 'Required.',
        required: v => !!(v && v.length) || 'Item is required',
        min: v => v.length >= 8 || 'Min 8 characters',
        passwordRules: v => !!v || "Password is required",
        confirmPasswordRules: v => !!v || "Confirmation password is required",
        zip: v => /^[0-9]{5}([- ]?[0-9]{4})?$/.test(v) || 'U.S. postal code required (ZIP+4 format)',
        phone: v => /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(v) || 'U.S. phone format required (eg. (123) 123-1234)'
      },

      dialogStep3: false,
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      cities_by_google: [],
      filterArea: {},
      filterCountry: [],
      selectedCities: []
      // END
    }

  },
  mounted: function () {
    this.getFormData();
    this.fetchCategories();
    //this.fetchPrivacy();
    //this.fetchTerms();
    //this.fetchCountries();
  },
  methods: {
    previousStep() {
      this.step--;
    },
    nextStep() {
      if(this.validateStep(this.step)){
        if(this.step == 5){
          this.onComplete();
        }else{
          this.step++;
        }
      }
    },
    onSubmit(){

      this.validateStep(this.step);
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities_by_google = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));

    },
    nameWithCode({name, code}) {
      return `${ name } - [${ code }]`;
    },

    validateStep(step) {

      console.log("validate step:" + step);
      this.errors = [];
      let label = '';

      if(step == 1){
        if (!this.formData.step1.name) {

          label = "Name required.";
          this.errors.push(label);
          this.errors["company_name"] = label;
        }
      }else if(step == 2){

        if (!this.formData.step2.owner_first_name) {
          label = "First name required.";
          this.errors["owner_first_name"] = label;
          this.errors.push(label);
        }
        if (!this.formData.step2.owner_last_name) {
          label = "Last name required.";
          this.errors["owner_last_name"] = label;
          this.errors.push(label);
        }
        if (!this.formData.step2.address1) {
          label = "Address1 required.";
          this.errors["address1"] = label;
          this.errors.push(label);
        }

        if (!this.formData.step2.zip) {
          label = "Zip required.";
          this.errors.push(label);
          this.errors["zip"] = label;
        }else if(!this.validZip(this.formData.step2.zip)){
            label = "U.S. postal code required (ZIP+4 format).";
            this.errors.push(label);
            this.errors["zip"] = label;
        }

        /*
        if (!this.formData.step2.address2) {
          label = "Address2 required.";
          this.errors["address2"] = label;
          this.errors.push(label);
        }
        */

        if (this.formData.step2.city.length == 0) {
          label = "City required.";
          this.errors["city"] = label;
          this.errors.push(label);
        }
        if (this.formData.step2.state.length == 0) {
          label = "State required.";
          this.errors["state"] = label;
          this.errors.push(label);
        }

      }else if(step == 3){

        if (!this.formData.step3.phone) {
          label = "Phone required.";
          this.errors["phone"] = label;
          this.errors.push(label);
        }else if(!this.validPhone(this.formData.step3.phone)){
            label = "U.S. phone format required (E.g (308)-135-7895 or 308-135-7895 or 308135-7895 or 3081357895).";
            this.errors.push(label);
            this.errors["phone"] = label;
        }

      }else if(step == 5){

        if (!(this.formData.step4.set_my_available_dates == 0 || this.formData.step4.set_my_available_dates == 1)) {
          label = "Vendor availability required.";
          this.errors["set_my_available_dates"] = label;
          this.errors.push(label);
        }
        if (!(this.formData.step4.availability_check == 'package' || this.formData.step4.availability_check == 'vendor')) {
          label = "Vendor availability required.";
          this.errors["availability_check"] = label;
          this.errors.push(label);
        }
        if (!(this.formData.step4.auto_booking === true || this.formData.step4.auto_booking === false)) {
          label = "Booking option required.";
          this.errors["auto_booking"] = label;
          this.errors.push(label);
        }

      }else if(step == 6){
        if (!this.formData.step5.privacy_validate && !Number.isInteger(this.formData.step5.privacy_validate)) {
          label = "Privacy required.";
          this.errors["privacy_validate"] = label;
          this.errors.push(label);
        }
        if (!this.formData.step5.terms_validate && !Number.isInteger(this.formData.step5.terms_validate)) {
          label = "Terms required.";
          this.errors["terms_validate"] = label;
          this.errors.push(label);
        }
      }


      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    /*
     validate() {
        this.$v.formData.$touch();
        var isValid = !this.$v.formData.$invalid
        //this.$emit('on-validate', this.$data, isValid)
        return isValid
    },
    */
    validPhone: function (v) {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return re.test(v);
    },
    validZip: function (v) {
      var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(v);
    },    
    async onComplete() {


      console.log("Complete");

      if(!this.valid){
        return;
      }

      this.$http.post('/api/vendor/onboarding', this.formData)
          .then(({data}) => {
            this.$toast.success(data.message, "Success");
            // Fetch the user.
            this.$store.dispatch('auth/fetchUser')
            this.showSuccess = true;
          })
          .catch((error) => {
            // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              //console.log('Error response', error.response);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log('Error request.response', error.request.response);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error message', error.message);
            }
            //console.log('Error', error);
          })
          .finally(() => {
            this.showSuccess = true;
            //this.$router.push({name: 'feed'})
          })
    },
    getFormData() {
      this.loading = true;
      let uri = '/api/vendor/companies';
      this.$http.get(uri).then((response) => {
        this.formData.step1.name = response.data.data.name;
        this.loading = false;
      });
    },
    fetchCategories() {
      this.loading = true;
      this.$http.get('api/categories')
          .then(({data}) => {
            this.categories = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchPrivacy() {
      this.loading = true;
      this.$http.get('api/privacy')
          .then(({data}) => {
            this.privacy = data.data;
            //this.formData.step5.privacy_validate = data.data.id;
          })
          .catch(error => {
            console.log('Error loading privacy data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchTerms() {
      this.loading = true;
      this.$http.get('api/terms')
          .then(({data}) => {
            this.terms = data.data
            //this.formData.step5.terms_validate = data.data.id;
          })
          .catch(error => {
            console.log('Error loading terms data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCountries() {
      this.loading = true;
      this.$http.get('api/countries')
          .then(({data}) => {
            this.countries = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCities(query) {
      console.log(query);
      const params = {
        "filter[name]": query,
        "filter[country_id]": this.formData.step4.countries,
        "page": this.$route.query.page
      };

      this.loadingCities = true;
      this.$http.get('api/cities', {params})
          .then(({data}) => {
            this.cities = this.cities.concat(data.data);
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCities = false
          )

    },
    fetchCity(query) {

      const params = {
        "filter[name]": query,
        "filter[country_id]": this.formData.step1.state,
        "page": this.$route.query.page
      };
      //console.log(params);
      this.loadingCity = true;
      this.$http.get('api/cities', {params})
          .then(({data}) => {
            this.cities = data.data
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCity = false
          )

    },
    async fetchAreas(val) {

      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;

      const params = {
        "filter[name]": query.city_name,
        //"filter[country_name]" : query,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      await this.$http.get('api/areas', {params})
          .then(({data}) => {
            this.cities = data.data;
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          });
      this.formData.step2.city = this.cities.map(a => a.id);
      this.formData.step2.state = this.cities.map(a => a.country_id);
      this.validateStep(this.step);

    },
    clearAll() {
      this.cities = []
    },
    updateSlug: function (val) {
      this.formData.step1.slug = val;
    },
    saveSlug: function (val) {
      //console.log(val);
      this.formData.step1.slug = val;
      this.updateItem();
    },
    resetSlug() {
      this.formData.step1.slug = null;
    },
  },
  watch: {
    /*
   'formData.step4.countries': function(){
    this.formData.step4.countrylist = this.formData.step4.countries.map(a => a.id);
    },
    'formData.step4.cities': function(){
    this.formData.step4.citylist = this.formData.step4.cities.map(a => a.id);
    },
    */
    searchCities(val) {

      if (this.searchCities == null || this.searchCities.length < 3) {
        return
      }

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);

      /*
      if(this.searchCities === null) return
      if (this.searchCities.length < 3) return

      // Items have already been loaded
      //if (this.formData.step4.cities.length > 0) return

      // Items have already been requested
      if (this.loadingCities) return

      this.loadingCities = true


      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      //debounce(this.fetchCities, 200)(val, this)

      setTimeout(function(){
          this.fetchCities(val)
      }.bind(this),200);

      */

    },
    selectedCities: function () {

      if (!this.selectedCities || this.selectedCities === null) {
        this.formData.step2.city = [];
        this.formData.step2.state = [];
        this.filterCountry = [];
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        google_place_id: this.selectedCities.id

      };
      this.fetchAreas(this.filterArea);

    },

  },
  computed: {
    url() {
      return window.location.origin;
    },
    slug() {
      return Slug(this.company.name);
    }
  }

}
</script>

<style>

.phone {
  background: #fff;
  border-radius: 10px;
  padding: 0px;
  transition: all 0.2s;
}
</style>
